import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"
import SolutionsSidebar from "@components/solutionsSidebar"

const BackupDisasterRecovery = ({ data }) => (
  <Layout>
    <Seo
      title="Backup and Disaster Recovery"
      description={data.site.siteMetadata.description}
    />
    <div className="page-headline">
      <div className="container">
        <h6 className="text-white-50 text-uppercase">Solutions</h6>
        <h1>Backup and Disaster Recovery</h1>
      </div>
    </div>
    <div className="page-content bg-light">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-7 col-lg-8">
            <p>
              Does your company have a disaster recovery plan? What about a
              backup in place to protect your company information? If so, does
              it include a quick and easy recovery for your business information
              and decreased downtime in the event of a disaster?
            </p>
            <p>
              Every company should have a form of a disaster recovery plan along
              with backups, in place, due to chance of a disaster striking.
              Although each plan will vary based on each company, a disaster
              recovery plan is necessary in order to smoothly restore your
              company network in cases of disaster related to anything from
              cyber-attacks, human error, or even natural disaster.
            </p>
            <p>
              Backup of systems and networks is necessary on top of a disaster
              recovery plan, to ensure that data and important company
              information is not lost.
            </p>
            <p>
              We provide tailored backup and disaster recovery plans for
              business based on their needs, locations, and business attributes.
              Because every business is different, your company needs a specific
              plan of its own to ensure a speedy recovery after a disaster
              strikes.
            </p>
            <h4>
              Without these plans and backups in place, businesses can face
              multiple losses including:
            </h4>
            <ul className="crossed-list">
              <li>Decreased productivity due to downtime</li>
              <li>Loss of personal and classified information</li>
              <li>Stolen information due to cyber-attack</li>
              <li>Complete destruction to network</li>
              <li>Loss in daily profits due to downtime</li>
              <li>Total loss of company information</li>
            </ul>
            <p>
              If your company does not have a disaster recovery plan, backups,
              or even need a better plan in place for their company, contact us
              today. We want to help your company protect itself from possible
              damage, loss, or theft. Call us today to get a quote, inquire
              about more information, or set up a meeting to evaluate your
              business disaster recovery plan!
            </p>
          </div>
          <div className="col-md-4 col-lg-3">
            <SolutionsSidebar />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default BackupDisasterRecovery

export const BackupDisasterRecoveryQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
